<template>
  <div>
    <el-dialog
      title="编辑标签"
      :visible.sync="flagStatus"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <!-- //档案类别单选框(radio),购买状态单选框(radio),且都是必填字段 -->
        <el-form-item
          label="标签名称"
          prop="name"
          :label-width="formLabelWidth"
          :required="true"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          label="说明备注"
          prop="remark"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.remark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { putBasicConfig } from "@/api/rank/tags";
export default {
  name: "CDMTagsCompile",
  data() {
    return {
      flagStatus: false,
      form: {},
      formLabelWidth: "120px",
    };
  },
  //监视flagStatus的变化
  watch: {
    flagStatus: function(val) {
      if (val) {
        this.form = this.listData;
      }
    },
  },
  methods: {
    handleClose() {
      this.handleCancel();
    },
    //确定
    handleConfirm() {
      const data = {
        name: this.form.name,
        remark: this.form.remark = undefined ? '' : this.form.remark,
      };
      putBasicConfig(this.form.id, data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "编辑成功",
          });
          this.flagStatus = false;
          this.$emit("getList");
        }
      });
    },
    handleCancel() {
      this.flagStatus = false;
      this.$emit("getList");
    },
  },
};
</script>

<style lang="scss" scoped></style>
